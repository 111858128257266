<template>
	<NotificationActionPlanSatisfaction />
</template>
<script>

import NotificationActionPlanSatisfaction from '@/components/satisfaction-action-plans/notification-action-plan-satisfaction/index.vue'

export default {
	name: 'NotificationActionPlanSatisfactionPage',
	components: { NotificationActionPlanSatisfaction },
};
</script>
