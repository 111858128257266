import { mapState, mapActions } from "vuex";
import { showError } from "@/helpers/globalHelpers";
import moment from "moment";

export default {
  name: 'ListActionPlanSatisfaction',

  data: () => ({
    loadingCostCenter: false,
    loadingActionPlan: false,
    isLoadingUsers: false,
    evidenceModalItem: null,
    openModalEvidence: false,
    headers: [
      { text: "Periodo", sortable: false, value: "register_date" },
      { text: "Plan de acción", sortable: false, value: "action_plan.description" },
      { text: "Servicio", sortable: false, value: "action_plan.service.name" },
      { text: "Gerencia", sortable: false, value: "action_plan.management.name" },
      { text: "Estado", sortable: false, value: "status" },
      { text: "Acciones", value: "actions", sortable: false },
    ],
    evidenceActionPlanFilters: {
      page: 1,
      limit: 20 || process.env.VUE_APP_PAGINATION_PER_PAGE,
      pagination: true,
      status: ['PENDIENTE', 'NO_COMPLETADO', 'OBSERVADO'],
      service: null,
      month: parseInt(moment().format('M')),
      year: parseInt(moment().format('Y')),
    },
    status: [
      { name: "COMPLETADO", value: "COMPLETADO" },
      { name: "PENDIENTE APR", value: "PENDIENTE" },
      { name: "NO COMPLETADO", value: "NO_COMPLETADO" },
      { name: "OBSERVADO", value: "OBSERVADO" },
      { name: "MODIFICADO", value: "MODIFICADO" }
    ],
    monthValues: [
      { name: "ENERO", value: 1 },
      { name: "FEBRERO", value: 2 },
      { name: "MARZO", value: 3 },
      { name: "ABRIL", value: 4 },
      { name: "MAYO", value: 5 },
      { name: "JUNIO", value: 6 },
      { name: "JULIO", value: 7 },
      { name: "AGOSTO", value: 8 },
      { name: "SETIEMBRE", value: 9 },
      { name: "OCTUBRE", value: 10 },
      { name: "NOVIEMBRE", value: 11 },
      { name: "DICIEMBRE", value: 12 },
    ],
    yearValues: [
      { year: 2023 },
      { year: 2024 },
      { year: 2025 },
      { year: 2026 },
    ]
  }),

  created() {
    this.getCostCenterByUser();
    this.getEvidenceActionPlan();
    this.loadUsers();
  },

  computed: {
    breadcrumbs() {
      return [
        {
          text: "Inicio",
          link: true,
          exact: true,
          disabled: false,
          to: {
            name: "Dashboard",
          },
        },
        {
          text: "Planes de acción",
          link: true,
          exact: true,
          disabled: true,
        },
      ];
    },

    ...mapState("auth", ["user"]),
    ...mapState("security", [
      // "managements", 
      // "costCenters"
      "usersActive",
      "costCentersUser"
    ]),
    ...mapState("action_plan", ["actionPlansPagination", "actionPlans"]),
    ...mapState("evidence_action_plan", ["evidencesActionPlan", "evidencesActionPlanPagination"]),

  },

  methods: {
    ...mapActions("security", [
      "getCostCenterUser",
      "getUsersActive"
    ]),

    ...mapActions("evidence_action_plan", [
      "listActionPlanPagination",
      "reverseEvidence",
      "cleanEvidencesActionPlan"
    ]),

    loadUsers: async function () {
      this.isLoadingUsers = true;
      await this.getUsersActive();
      this.isLoadingUsers = false;
    },

    async getCostCenterByUser() {
      this.loadingCostCenter = true;
      const { error } = await this.getCostCenterUser({
        user_id: this.user.id,
      });
      if (error) showError(error);
      this.loadingCostCenter = false;
    },

    async getEvidenceActionPlan() {
      this.loadingActionPlan = true;

      const filters = { ...this.evidenceActionPlanFilters, type: 'SATISFACCION_CLIENTE' };

      if (filters.status && filters.status.length > 0) {
        filters.status = filters.status.join(',');
      } else {
        delete filters.status;
      }

      const { error } = await this.listActionPlanPagination(filters);
      if (error) showError(error);
      this.loadingActionPlan = false;
    },

    closeModalEvidence() {
      this.openModalEvidence = false;
    },

    formatDate(dateString) {

      if (!dateString) {
        return "-";
      }

      const dateOnly = moment.utc(dateString).format("DD/MM/YYYY");

      return dateOnly;
    },

    allowUpdate(evidenceActionPlan) {
      if (evidenceActionPlan &&
        evidenceActionPlan.action_plan.status === 'MODIFICADO' &&
        evidenceActionPlan.status !== 'MODIFICADO' ||
        evidenceActionPlan.status === 'COMPLETADO' ||
        evidenceActionPlan.status === 'PENDIENTE' ||
        evidenceActionPlan.status === 'OBSERVADO'
      ) {
        return false;
      } else if (evidenceActionPlan &&
        evidenceActionPlan.action_plan.status === 'MODIFICADO' &&
        evidenceActionPlan.status === 'MODIFICADO'
      ) {
        return true;
      } else {
        return true;
      }
    },

    setColorStatusEvidenceActionPlan(status) {
      switch (status) {
        case "EN_CURSO":
          return {
            background: "blue lighten-5",
            color: "blue",
            name: "EN CURSO",
            description: "Periodo en curso"
          };
        case "MODIFICADO":
          return {
            background: "yellow lighten-5",
            color: "yellow",
            name: "MODIFICADO",
            description: "Periodo en estado modificado"
          };
        case "COMPLETADO":
          return {
            background: "green lighten-5",
            color: "green",
            name: status,
            description: "Periodo completado"
          };
        case "ATRASADO":
          return {
            background: "grey lighten-3",
            color: "grey",
            name: status,
          };
        case "NO_COMPLETADO":
          return {
            background: "grey lighten-2",
            color: "grey",
            name: "NO COMPLETADO",
            description: "Periodo no completado"
          };
        case "PENDIENTE":
          return {
            background: "blue lighten-5",
            color: "blue",
            name: "PENDIENTE",
            description: "Periodo pendiente de aprobación"
          };
        case "OBSERVADO":
          return {
            background: "yellow lighten-4",
            color: "yellow darken-2",
            name: "OBSERVADO",
            description: "Periodo observado"
          };
      }
    },

    isAllowed(module, page, activityName) {
      const activities = this.$store.state.auth.activities || [];
      const found = activities.some(
        (activity) =>
          activity.module_name === module &&
          activity.page_name === page &&
          activity.activity_name === activityName
      );

      return found;
    },

    async confirmApprovedEvidence(evidenceActionPlan) {
      this.$swal({
        text: '¿Estás seguro de eliminar el periodo de este servicio?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#0039a6',
        cancelButtonColor: 'grey',
        cancelButtonText: 'Cancelar',
        confirmButtonText: 'Sí',
      }).then(async (result) => {
        if (result.isConfirmed) {

          const { error } = await this.reverseEvidence(evidenceActionPlan.id);
          if (error) showError(error);

          await this.getEvidenceActionPlan()

        }
      })
    },
  },
  beforeDestroy(){
    this.cleanEvidencesActionPlan();
  }
};