<template>
	<NotificationActionPlan />
</template>
<script>

import NotificationActionPlan from '@/components/action-plans/notification-action-plan/index.vue'

export default {
	name: 'NotificationActionPlanPage',
	components: { NotificationActionPlan },
};
</script>
